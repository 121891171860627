<template>
    <div class=link-btn>
        [<a @click="exportFilteredDisplayable(props.dataToDownload, props.fileName)">Export csv</a>]
    </div>
</template>
<script setup lang="ts" generic="T, K extends keyof T">
import DbTableCsvHelper from '@/services/helpers/DbTableCsvHelper';
import useNotifier from "@/services/composables/Notifier";
const props = defineProps<{
    dataToDownload: T;
    fileName: string;
}>();
function exportFilteredDisplayable(dataToDownload, fileName) {
    if (dataToDownload.length) {
        DbTableCsvHelper.generateCsvDownload(dataToDownload, fileName);
    } else useNotifier().warn({ msg: 'There is nothing in the table to export.' });
}
</script>
<style scoped lang="scss">
.link-btn {
    margin-left: auto;
    padding: 0.1rem;
}
.link-btn > a {
    cursor: pointer;
    color: var(--theme-link-color);
    &:hover {
        text-decoration: underline;
        color: var(--theme-link-color-hover);
    }
}
</style>