<template>
    <Popup :modelValue="popUpOpen == true"
        @update:model-value=closeChild
        title="Previous Reports"
        hasClose
    >
        <template #contents>
            <div style="min-height: 75px">
            <Spinner v-show="isLoading ==true" />
                <Table
                :Rows=pastEoPVerificationReports
                :Cols=historyCols
                :Labels=historyLabels
                :DefaultSortCols=defaultSortCols
                :DefaultSortOrders=defaultSortOrders
                >
                    <template #PerformedOn="props">
                        <div>
                            {{ props.value.PerformedOn.replace("T", " ") }}
                        </div>
                    </template>
                </Table>
            </div>
        </template>
    </Popup>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import Popup from "@/components/Popup.vue";
import Table from "@/components/TablePersistentChecking.vue";
import { DisplayableKeys } from "@/services/SorterService";
import IEoPVerificationRequestParams from "@/Interfaces/EoPVerifications/IEoPVerificationRequestParams";
import { eoPVerificationReport } from "@/services/network";
import EoPVerificationReportOperation from "@/Interfaces/EoPVerifications/EoPVerificationReportOperation";
import useNotifierWithErrFormatter from "@/services/composables/NotifierWithErrFormatter";
import { useRoute } from "vue-router";
import Spinner from '@/components/Spinner.vue';


const historyCols: DisplayableKeys<EoPVerificationReportOperation>[] = ["Category", "EoPName", "ClosingDate", "PerformedOn", "PerformedBy","EoPStatus", "JournalingStatus", "AmountExpected", "AmountReceived", "AmountDeviation"];
const historyLabels = [ "Category", "Type", "Closing", "Processed", "User", "Status", "Journaling Status", "Amount Expected", "Amount Received", "Amount Deviation"];
const defaultSortCols= [ historyCols.findIndex((col) => col === "PerformedOn" as keyof EoPVerificationReportOperation ) ];
const defaultSortOrders = ref<(1|-1)[]>([-1]);
const route = useRoute();
const journalType = route.path.split("/").pop() as string;
const market = route.query.market?.toString() as string;
const period = route.query.period?.toString() as string;
const props = defineProps<{
    modelValue?: boolean;
}>();
const isLoading = ref(false)
const pastEoPVerificationReports = ref<EoPVerificationReportOperation[]>();
const popUpOpen = computed({
    get() {
        return props.modelValue
    },
    set(val) {
        emits('update:modelValue', val);
    }
});
const emits = defineEmits<{
    (e: 'update:modelValue', val?: boolean);
    (e: 'confirm');
}>();
watch(popUpOpen, (val) => {
    if(val == true){
        GetPastEoPVerificationReport(journalType, market, period)
    }
})

async function GetPastEoPVerificationReport(journalType: string, market: string, period: string) {
    if(isLoading.value == true) return
    try {
        isLoading.value = true
        const eoPRequest: IEoPVerificationRequestParams = {
            market: market, 
            period: period, 
            eoPType: journalType,
            mostRecentOnly: false
        }
        const data = (await eoPVerificationReport.get(eoPRequest)).data
        pastEoPVerificationReports.value = data
    } catch (error) {
        useNotifierWithErrFormatter().error({error, errorType: "Failed to download past EoP verification reports."});
    }
    finally{
        isLoading.value = false
    }
}
function closeChild(){
    popUpOpen.value = false;
}
</script>

<style scoped>
.grid-item {
    padding: 10px;
}
</style>