<template>
    <div class="EoPVerificationReport">
        <div v-if="isLoadingReport || isLoadingNewReport" class="content-container loader-style">
            <div class="loading loader-style">
                <span class="loader"></span>
            </div>
        </div>
        <div v-else class="content-container">
            <div class="top-content">
                <div class="top-container">
                    <div class="eop-v-info">
                        <tbody>
                            <tr>
                                <td style="font-weight: bold;">Name:</td>
                                <td style="font-weight: bold;">{{ ToCapitalSentenceCase(journalType) }}</td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">Period:</td>
                                <td style="font-weight: bold;">{{ period }}</td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">Status:</td>
                                <td>
                                    <div
                                        v-if="
                                        currentEoPVerificationReport?.EoPStatus.toUpperCase().startsWith(
                                                'ERROR'
                                            )
                                        "
                                        class="failure"
                                    >
                                        {{ currentEoPVerificationReport?.EoPStatus }} - DEVIATIONS DETECTED
                                    </div>
                                    <div
                                        v-else-if="currentEoPVerificationReport?.EoPStatus.toUpperCase() == 'SUCCESS'"
                                        class="highlight-green-bold"
                                    >
                                        {{ currentEoPVerificationReport?.EoPStatus }}
                                    </div>
                                    <div v-else>{{ currentEoPVerificationReport?.EoPStatus }}</div>
                                </td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">Journaling Status:</td>
                                <td>
                                    <div
                                        v-if="currentEoPVerificationReport?.JournalingStatus.toUpperCase() == 'INCOMPLETE'"
                                        class="failure"
                                    >
                                        {{ currentEoPVerificationReport?.JournalingStatus }} 
                                    </div>
                                    <div
                                        v-else-if="currentEoPVerificationReport?.JournalingStatus == 'COMPLETE'"
                                        class="highlight-green-bold"
                                    >
                                        {{ currentEoPVerificationReport?.JournalingStatus }}
                                    </div>
                                    <div v-else>{{ currentEoPVerificationReport?.JournalingStatus }}</div>
                                </td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">Processed:</td>
                                <td>{{ currentEoPVerificationReport?.PerformedOn.replace("T", " ") }}</td>
                            </tr>
                            <tr>
                                <td colspan="2"></td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">Total Amount Expected:</td>
                                <td>{{ currentEoPVerificationReport?.AmountExpected }}</td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">Total Amount Received:</td>
                                <td>{{ currentEoPVerificationReport?.AmountReceived }}</td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">Total Amount Deviation:</td>
                                <td>{{ currentEoPVerificationReport?.AmountDeviation }}</td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">Number of Errors:</td>
                                <td>{{ currentEoPVerificationReport?.NumberOfErrors }}</td>
                            </tr>
                        </tbody>
                    </div>
                    <div class="new-report-button-div">
                        <button
                            @click="CreateNewReport()"
                            :disabled="DenyFunctionLevelAccess(FunctionAccessLevels.CreateEoPVReport)"
                            :title="DenyFunctionLevelAccess(FunctionAccessLevels.CreateEoPVReport) ? 'Create New Report (Access Denied)' : 'Create New Report'"
                            class="new-report-button"
                        >
                            Create New Report
                        </button>
                        <button
                            @click="popUpOpen = true"
                            title="View Report History"
                            class="new-report-button"
                        >
                            View Report History
                        </button>
                    </div>

                </div>
            </div>
            <div v-if=!!ReportConstituentItems>
                <div class="table-header-box">
                    <h2>Detailed Report</h2>
                    <ExportCsv
                    :dataToDownload="filteredTableData"
                    :fileName="ToCapitalSentenceCase(journalType) + '-' + period + '-' + 'EoP Report'"
                    />
                </div>
                <div class="table-container">
                    <Table
                    ref=table
                    :Rows=ReportConstituentItems
                    :Cols=ReportConstituentItemsCols
                    :Labels=ReportConstituentItemsLabels
                    v-model:RowsPerPage=rowsPerPageConstituentItems
                    >
                        <template #Status="{value}">
                            <TickCrossBooleanDisplay :value="value.Status" />
                        </template>
                    </Table>
                </div>
            </div>
        </div>
    </div>
    <EopVerificationReportHistoryPopUp
    v-model="popUpOpen"
    />
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { FunctionAccessLevels, DenyFunctionLevelAccess } from "@/config/AuthConfig";
import { useRoute } from "vue-router";
import log from "loglevel";
import { authService } from "@/auth/authSingleton";
import EopVerificationReportHistoryPopUp from "./EopVerificationReportHistoryPopUp.vue";
import { DisplayableKeys } from "@/services/SorterService";
import TickCrossBooleanDisplay from "@/components/TickCrossBooleanDisplay.vue";
import Table, {ITableExpose} from "@/components/TablePersistentChecking.vue";
import { eoPCreateNewReport, eoPVerificationReport } from "@/services/network";
import IEoPCreateNewReport from "@/Interfaces/EoPVerifications/IEoPCreateNewReport";
import EoPVerificationReportOperation from "@/Interfaces/EoPVerifications/EoPVerificationReportOperation"
import EoPReportSpecifications from "@/Interfaces/EoPVerifications/EoPReportSpecifications";
import useNotifierWithErrFormatter from "@/services/composables/NotifierWithErrFormatter";
import IEoPVerificationRequestParams from "@/Interfaces/EoPVerifications/IEoPVerificationRequestParams";
import ExportCsv from "@/components/ExportCsv.vue";

const userName = ref("");
const route = useRoute();
const journalType = route.path.split("/").pop() as string;
const market = route.query.market?.toString() as string;
const period = route.query.period?.toString() as string;
const filteredTableData = computed(() => {
      return table.value?.GetFiltered() ?? [];
});
const popUpOpen = ref(false)

onMounted(async () => {
    log.trace("onMounted() EoPVerificationReport");
    document.title = `EoP Verification (${journalType}) - Optio`;
    userName.value = authService.getEmailAddress()
    await GetEoPVerificationReport(journalType,market,period).then(() => {});
});

// Get EoP Verification Report Data from API
async function GetEoPVerificationReport(journalType: string, market: string, period: string) {
    if(isLoadingReport.value) return
    try {
        isLoadingReport.value = true
        const eoPRequest: IEoPVerificationRequestParams = {
            market: market, 
            period: period, 
            eoPType: journalType,
            mostRecentOnly: true
        }
        const data = (await eoPVerificationReport.get(eoPRequest)).data
        currentEoPVerificationReport.value = data[0]
        const reportConstituentItems = data[0]?.EoPSpecifications.map( x => {
            x.ItemType = ToCapitalSentenceCase(x.ItemType)
            return x
        })
        ReportConstituentItems.value = reportConstituentItems
        isLoadingReport.value = false
    } catch (error) {
        useNotifierWithErrFormatter().error({error, errorType: "Failed to download EoP verification reports."});
    }finally{
        isLoadingReport.value = false
    }
}

// TABLE
const isLoadingNewReport = ref(false);
const isLoadingReport = ref(false);
const currentEoPVerificationReport = ref<EoPVerificationReportOperation>();
const ReportConstituentItems = ref<EoPReportSpecifications[]>()
const ReportConstituentItemsCols: DisplayableKeys<EoPReportSpecifications>[] = ["ItemId", "ItemType", "Status", "ErrorTypes", "AmountExpected", "AmountReceived", "AmountDeviation", "JournalingStatus"];
const ReportConstituentItemsLabels: string[] = ["Item ID", "Item Type", "Status", "Error Types", "Amount Expected", "Amount Received", "Amount Deviation", "Journaling Status"];
const rowsPerPageConstituentItems = 40;
const table = ref<ITableExpose<EoPVerificationReportOperation>>();

// Send POST request to create new EoP Verification Report
async function CreateNewReport() {
    if(isLoadingNewReport.value) return
    try {
        isLoadingNewReport.value = true;
        const eoPNewReportRequestBody:IEoPCreateNewReport = {
        market: market? market.toString() : "",
        periodMonth: period? period : "",
        eoPType: journalType!,
        userName: userName.value
        }
        await eoPCreateNewReport.post(eoPNewReportRequestBody)
        useNotifierWithErrFormatter().success({msg: "New EoP Verification Report has been created"})
        await GetEoPVerificationReport(journalType,market,period)
    } catch (error ){
        useNotifierWithErrFormatter().error({error, errorType: "Error when creating new EoP verification report"})
    } finally {
        isLoadingNewReport.value = false;
    }
}
function ToCapitalSentenceCase(string: string){
    return string.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (character: string) => character.toUpperCase())
}
</script>

<style scoped lang="scss">
.loader-style{
    min-height: 500px;
}
.table-header-box{
    display: flex;
    align-items: flex-end;
}
.eop-v {
    padding: 2%;
}
.eop-v-info {
    padding: 2%;
}
.eop-v-new-report {
    padding-left: 2%;
}
.eop-v-reports {
    padding-bottom: 2%;
}
.eop-v-reports h2 {
    display: flex;
    justify-content: center;
    align-items: center;
}
.new-report-button {
    border-radius: var(--buttons-border-radius);
    background-color: var(--primary-color-lighter);
    padding: 10px;
    color: blue;
    font-size: larger;
    font-weight: bold;
    border: none;
}
.new-report-button:hover {
    cursor: pointer;
    background-color: var(--primary-color);
    color: white;
}
.new-report-button:disabled {
    border-width: 1px;
    color: var(--primary-color-dark);
    background-color: var(--light-grey);
    cursor: not-allowed;
}
.new-report-button:visited {
    color: inherit;
}
.file-list {
    list-style-type: none;
}
.new-report-button-div {
    display: flex;
    flex-direction: column;
    gap: 2em;
    padding-top:5%;
    padding-right:4%;
}
.invoice-scroll-box {
    height: 95px;
    width: 130px;
    overflow-y: scroll;
}
.top-container {
    display: flex;
    align-items: start;
    justify-content: space-between;
}
:deep(.content-container){
    row-gap: 0px !important;
}
:deep(.vtl-container){
    min-height: 100px;
}
</style>