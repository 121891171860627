import NetworkService from "./NetworkService";
import ILedgerTransactionOptions from "@/Interfaces/Ledgering/ILedgerTransactionOptions";
import ILedgerTransactions from "@/Interfaces/Ledgering/ILedgerTransactions";
import ILedgerTransaciosParams from "@/Interfaces/Ledgering/ILedgerTransactionsParams";
import ILedgerTransactionsQuery from "@/Interfaces/Ledgering/ILedgerTransactionsQuery";
import ILedgerTransactionPostResponse from "@/Interfaces/Ledgering/ILedgerTransactionPostResponse";
import ILedgerTransactionRequest from "@/Interfaces/Ledgering/ILedgerTransactionRequest";
import IContractResponse from "@/Interfaces/Contract/IContractResponse";
import IContractPostRequest from "@/Interfaces/Contract/IContractPostRequest";
import IContractValidationResponse from '@/Interfaces/Contract/IContractValidationResponse';
import IContractValidateRequest from "@/Interfaces/Contract/IContractValidateRequest";
import IContractValidateParams from "@/Interfaces/Contract/IContractValidateParams";
import IContractValidationOverrideRequest from "@/Interfaces/Contract/IContractValidationOverrideRequest";
import IContractApproveRequest from "@/Interfaces/Contract/IContractApproveRequest";
import IContractApproval from "@/Interfaces/Contract/IContractApproval";
import IContractQuery from "@/Interfaces/Contract/IContractQuery";
import IRechargeInvoicePaymentItems from "@/Interfaces/Payments/IRechargeInvoicePaymentsItem";
import IConfirmRechargeInvoicePaymentRequest from "@/Interfaces/Payments/IConfirmRechargeInvoicePaymentRequest";
import IServiceFees from "@/Interfaces/FeesConfiguration/IServiceFees";
import IMarketQuery from "@/Interfaces/IMarketQuery";
import IFinancingFees from "@/Interfaces/FeesConfiguration/IFinancingFees";
import IChurnPaymentItem from "@/Interfaces/Payments/IChurnPaymentItemRaw";
import IConfirmChurnPaymentRequest from "@/Interfaces/Payments/IConfirmChurnPaymentReq";
import FCNetworkService from "./ForceCamelCaseNetworkService";
import IConfirmServiceInvoicePaymentRequest from "@/Interfaces/Payments/IConfirmServiceInvoicePaymentRequest";
import IServiceInvoicePaymentItem from "@/Interfaces/Payments/IServiceInvoicePaymentItemRaw";
import ICustomerViewParams from "@/Interfaces/ICustomerViewParams";
import ICustomerTable from '@/Interfaces/CustomersAndContracts/ICustomerTable'
import IInsuranceForm from "@/Interfaces/InsuranceClaims/IInsuranceClaimsForm";
import IInsuranceClaimsGetQuery from "@/Interfaces/InsuranceClaims/IInsuranceClaimsGetQuery"
import FileNetworkService from "./FileNetworkService";
import PostFileNetworkService from "./PostFileNetworkService";
import DatePeriod from "@/types/DatePeriod";
import { IMantisIssues } from "@/Interfaces/AlertsAndWarnings/IMantisIssues";
import { MarketSelection } from "../helpers/Helper";
import { NetworkSvcType } from "./BaseNetworkService";
import IContractData from "@/Interfaces/ContractApproval/IContractData";
import IForecast from "@/models/Portfolio/IForecast";
import { IEstimatedPortfolio, IEstimatedPortfolioV1 } from "@/models/Portfolio/IEstimatedPortfolio";
import IForecastQuery, { IEstForecastQueryV1, IForecastQueryV1 } from "@/Interfaces/Portfolio/IForecastQuery";
import IForecastDetails, { IForecastDetailsV1 } from "@/models/Portfolio/IForecastDetails";
import IForecastConfig from "@/models/Portfolio/IForecastConfig";
import IInterestPayment from "@/Interfaces/Ledgering/IInterestPayment";
import IInterestPaymentRequest from "@/Interfaces/Ledgering/IInterestPaymentRequest";
import IBankTransferPayments from "@/Interfaces/Ledgering/IBankTransferPayments";
import IBankTransferApproveOrConfirm from "@/Interfaces/Ledgering/IBankTransferApproveOrConfirm";
import { IEoPVerifications } from "@/Interfaces/EoPVerifications/IEoPVerifications";
import IEoPCreateNewReport from "@/Interfaces/EoPVerifications/IEoPCreateNewReport";
import EoPVerificationReportOperation from "@/Interfaces/EoPVerifications/EoPVerificationReportOperation";
import IEoPVerificationRequestParams from "@/Interfaces/EoPVerifications/IEoPVerificationRequestParams";

// ledgers
export const ledgerTransactionOptions =
  new NetworkService<ILedgerTransactionOptions>(
    process.env.VUE_APP_API_LEDGER_TRANSACTIONS_OPTIONS ??
      'ledger-transaction-options'
  );
export const ledgerTransactions = new NetworkService<
  ILedgerTransactions,
  ILedgerTransaciosParams,
  ILedgerTransactionsQuery,
  ILedgerTransactionRequest,
  ILedgerTransactionPostResponse
>(
  process.env.VUE_APP_API_LEDGER_TRANSACTIONS ?? 'ledger-transactions/{market}'
);
// hire agreements
export const contractHireAgreements = new NetworkService<
  IContractResponse,
  IContractPostRequest
>(
  process.env.VUE_APP_CONTRACT_HIRE_AGREEMENT_URL_PARAMS ?? 'contract-hire-agreement'
);
// hire agreements validations
export const contractValidations = new NetworkService<
  IContractValidationResponse,
  IContractPostRequest
>(
  process.env.VUE_APP_CONTRACT_VALIDATIONS_URL_PARAMS ?? 'contract-approval/validation-status'
);
export const contractApprovalValidate = new NetworkService<
  null,
  IContractValidateParams,
  IContractValidateRequest
>(
  process.env.VUE_APP_CONTRACT_APPROVAL_VALIDATE_URI ?? 'contract-approval/validation/{validationType}'
);
// post override contract validation
export const contractApprovalValidationOverride = new NetworkService<
  null,
  IContractValidateParams,
  IContractValidationOverrideRequest
>(
  process.env.VUE_APP_CONTRACT_APPROVAL_OVERRIDE_URI ?? 'contract-approval/override/{validationType}'
);
export const contractApproval = new NetworkService<
  IContractApproval[],
  undefined,
  IContractQuery,
  IContractApproveRequest,
  string[]
>(
  process.env.VUE_APP_CONTRACT_APPROVAL_URI ?? 'contract-approval'
);
export const serviceFees = new NetworkService<
  IServiceFees[],
  undefined,
  IMarketQuery,
  IServiceFees
>(
  process.env.VUE_APP_SERVICE_FEES_URL_PARAMS ?? 'service-fees'
);
export const financingFees = new NetworkService<
  IFinancingFees[],
  undefined,
  IMarketQuery,
  IFinancingFees
>(
  process.env.VUE_APP_FINANCING_FEES_URL_PARAMS ?? 'financing-fees'
);
export const interestPayments = new NetworkService<
  IInterestPayment[],
  undefined,
  IMarketQuery,
  IInterestPayment
>(
  process.env.VUE_APP_INTEREST_PAYMENTS_URL_PARAMS ?? 'interest-payments'
);

export const manualCreditScore = new NetworkService<null,{
  username: string;
  manualCreditScore: number;
  contractID: string;
}>(
  process.env.VUE_APP_MANUAL_CREDIT_SCORE_URI ?? 'credit-application/manual-credit-score'
);
export const euSecuritisationReport = new FileNetworkService<{
  type: string;
  period: DatePeriod;
},{
  ownerID: string;
}>(
  process.env.VUE_APP_GET_SECURITISATION_REPORT_URL_PARAMS ?? 'reports/{type}/{period}'
);
export const contractHireAgreementPDF = new PostFileNetworkService<{invoiceIds: string[]}>(
  process.env.VUE_APP_CONTRACT_HIRE_AGREEMENT_DOWNLOAD_URL_PARAMS ?? 'contract-hire-agreement/download'
);
//Recharge Invoice Payments
export const rechargeInvoicePayments = new NetworkService<IRechargeInvoicePaymentItems[]>(
  process.env.VUE_APP_UNPAID_RECHARGE_INVOICES_URL_PARAMS ?? 'unpaid-recharge-invoices'
);
export const confirmRechargeInvoicePayment = new NetworkService<null, IConfirmRechargeInvoicePaymentRequest >(
  process.env.VUE_APP_CONFIRM_RECHARGE_INVOICE_PAYMENTS_URL_PARAMS?? 'confirm-recharge-invoice-payment'
);

export const contractApprovalExport = new NetworkService<
IContractData[],
{
  owner: string;
  rvPeriod: string;
  contractIDs: string[];
}>(
  process.env.VUE_APP_GET_CONTRACT_APPROVAL_EXPORT_URL_PARAMS ?? 'approved-contracts-data'
);
export const customerView = new NetworkService<
  ICustomerTable|"",
  ICustomerViewParams
>(
  process.env.VUE_APP_CUSTOMER_VIEW_URI ?? 'database/{idOption}/{id}'
);
export const churnPayments = new FCNetworkService<IChurnPaymentItem[]|''>(
  process.env.VUE_APP_UNPAID_CHURN_PAYMENTS_URL_PARAMS ?? 'unpaid-churn-payments'
);
export const confirmChurnPayment = new NetworkService<null,IConfirmChurnPaymentRequest>(
  process.env.VUE_APP_CONFIRM_CHURN_PAYMENTS_URL_PARAMS ?? 'confirm-churn-payment'
);
export const confirmInterestPayment = new NetworkService<null, IInterestPaymentRequest>(
  process.env.VUE_APP_CONFIRM_INTEREST_PAYMENT_URL_NO_PARAMS ?? 'interest-payment'
);
export const serviceInvoicePayments = new FCNetworkService<IServiceInvoicePaymentItem[]>(
  process.env.VUE_APP_UNPAID_SERVICE_INVOICES_URL_PARAMS ?? 'unpaid-service-invoices'
);
export const confirmServiceInvoicePayment = new NetworkService<null,IConfirmServiceInvoicePaymentRequest>(
  process.env.VUE_APP_CONFIRM_SERVICE_INVOICE_PAYMENTS_URL_PARAMS ?? 'confirm-service-invoice-payment'
);
export const curPortfolioForecasts = new FCNetworkService<IForecast[], IForecastQueryV1>(
  process.env.VUE_APP_CURRENT_PORTFOLIO_FORECASTS_URI ?? 'forecast/operation'
);
export const curPortfolioForecast = new FCNetworkService<{ forecastResults: IForecastDetailsV1[] },{ id: number }>(
  process.env.VUE_APP_CURRENT_PORTFOLIO_FORECAST_DETAILS_URI ?? 'forecast/aggregate-result/{id}'
);
export const portfolioTemplate = new FCNetworkService<IEstimatedPortfolioV1, IMarketQuery, IEstForecastQueryV1>(
  process.env.VUE_APP_ESTIMATED_PORTFOLIO_TEMPLATE_URI ?? 'forecast/estimated-assignments/{market}'
)
export const estimateForecast = new FCNetworkService<{ forecastResults: IForecastDetailsV1[] }, IEstimatedPortfolio>(
  process.env.VUE_APP_ESTIMATED_PORTFOLIO_TEMPLATE_URI ?? 'forecast/estimated-portfolio'
);
export const forecastConfigs = new FCNetworkService<IForecastConfig[]>(
  process.env.VUE_APP_FORECAST_CONFIGS_URI ?? 'forecast/configuration'
)
export const mantisIssues = new NetworkService<IMantisIssues>(process.env.VUE_APP_ALERTS_AND_WARNINGS_TICKETS_URL_PARAMS ?? 'tickets', NetworkSvcType.TICKET);
export const ticketingSystem = new NetworkService<"", {
  exportAlertsAndWarnings: boolean;
  userName: string;
  market: keyof typeof MarketSelection
}>(process.env.VUE_APP_ALERTS_AND_WARNINGS_URL_PARAMS ?? 'ticketing-system', NetworkSvcType.TICKET);
export const getBankTransferPayments = new NetworkService<
IBankTransferPayments[], 
{
  market: string,
  status: string
}>(
  process.env.VUE_APP_GET_BANK_TRANSFER_PAYMENTS_URL_PARAMS ?? 'bank-transfers'
);
export const approveBankTransferPayment = new NetworkService<
null, 
IBankTransferApproveOrConfirm>(
  process.env.VUE_APP_APPROVAL_BANK_TRANSFER_PAYMENT_URL_PARAMS ?? 'bank-transfers/approval'
);
export const confirmBankTransferPayment = new NetworkService<
null, 
IBankTransferApproveOrConfirm>(
  process.env.VUE_APP_CONFIRMATION_BANK_TRANSFER_PAYMENTS_URL_PARAMS ?? 'bank-transfers/confirmation'
);
export const insuranceClaims = new NetworkService<
  IInsuranceForm[],
  null, 
  IInsuranceClaimsGetQuery,
  IInsuranceForm, 
  IInsuranceForm
>(process.env.VUE_APP_INSURANCE_URL_PARAMS ?? 'insurance' );
export const eoPVerificationHeader = new NetworkService<
IEoPVerifications, 
{
  market: string, 
  period: DatePeriod
}>(
  process.env.VUE_APP_EOP_VERIFICATION_HEADER_URL_PARAMS ?? 'eop-verification/header'
);
export const eoPCreateNewReport = new NetworkService<
null, 
IEoPCreateNewReport
>(
  process.env.VUE_APP_EOP_VERIFICATION_NEW_REPORT_URL_PARAMS ?? 'eop-verification'
);
export const eoPVerificationReport = new NetworkService<
EoPVerificationReportOperation[],
IEoPVerificationRequestParams
>(
  process.env.VUE_APP_EOP_VERIFICATION_OPERATION_URL_PARAMS ?? 'eop-verification/operation'
);