<template class="ChurnPayments">
    <div v-if="isLoading" class="content-container">
        <div class="loading">
            <span class="loader"></span>
        </div>
    </div>
    <div v-else class="content-container">
        <div class="top-content">
            <GettingChurnPaymentsErrorInfo
                :showError="!!showError"
                errorDescription="(Getting Churn Payments)"
                :error="infoError"
                :change="!!infoError"
            />
            <div class="flex-row-centered">
                <MarketSelector />
                <PeriodSelector v-model=period hasNullSelector id=periodSelector showTitle @update:model-value=updateSelection 
                        :filter="(p)=>allPayments?.some(x=>p.within(x.periodMonth))"/>
                <div class="center-buttons">
                    <button :disabled=!hasCheckboxes
                        @click="proceedToPayment"
                        class="incident-nav-button"
                    >
                        Confirm Payment
                    </button>
                </div>
            </div>
        </div>
        <div class="table-container">
            <TableLite
                :Rows="displayedPayments"
                :Cols="cols"
                :Labels="labels"
                :RowsPerPage="50"
                :HideTable="false"
                :HasCheckBox="hasCheckboxes"
                :UpdateCheckedRows="UpdateCheckedRows"
                :checkAll="false"
                :checkedRow=checkedRow
            />
        </div>
        <ChurnPaymentPopUp
            v-model=selectedRow
            @confirm="refreshAfterConfirm"
        />
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch, provide, inject } from "vue";
import { marketSelection } from "@/services/helpers/Helper";
import TableLite from "@/components/TableLite.vue";
import ChurnPaymentItem from "@/Interfaces/Payments/ChurnPaymentItem";
import ErrorInfo from "@/components/ErrorInfo.vue";
import ChurnPaymentPopUp from "@/components/ChurnPaymentPopUp.vue";
import DatePeriod from "@/types/DatePeriod";
import { churnPayments } from "@/services/network";
import PeriodSelector from "@/components/PeriodSelector.vue";
import MarketSelector from "@/components/MarketSelector.vue";
import useNotifier from "@/services/composables/Notifier";

const cols: (keyof ChurnPaymentItem)[] = [
    "journalInvoiceReference",
    "periodMonth",
    "balance",
    "dueDate"
];
const labels = [
    "Journal Invoice Reference",
    "Period Month",
    "Balance",
    "Due Date"
]
const page = ref(1);
provide("page", page);
const period = ref(new DatePeriod());

// ERRORS
const GettingChurnPaymentsErrorInfo = ErrorInfo;
const showError = ref(false);
const infoError = ref();

// Table
const isLoading = ref(false);
const allPayments = ref<ChurnPaymentItem[]>([]);
const displayedPayments = computed(() =>
    period.value.isValid() ?
        allPayments.value?.filter(x => x.periodMonth.valueOf() === period.value.valueOf()) :
        allPayments.value
);
const checkedRow = ref<ChurnPaymentItem>();
const hasCheckboxes = ref(false);
const selectedRow = ref<ChurnPaymentItem>();

onMounted(async () => {
    marketSelection.value = "GB";
    getPayments();
});
async function getPayments() {
    try{
        isLoading.value = true;
        const p = (await churnPayments.get()).data;
        allPayments.value = (p === '' ? [] : p).map(x=>new ChurnPaymentItem(x));

        if (period.value.isValid()) {
            updateSelection();
        }
    }catch(error) {
        infoError.value = error;
    }finally{
        isLoading.value = false;
    }
}
function updateSelection() {
    checkedRow.value = undefined;
    selectedRow.value = checkedRow.value!;

    hasCheckboxes.value = period.value.isValid();
}
function proceedToPayment() {
    selectedRow.value = checkedRow.value;
    if (!selectedRow.value) useNotifier().notify({msg:'No item selected'});
}
interface RowWrapper<T> {
    checked: boolean;
    value: T;
}
function UpdateCheckedRows(row: RowWrapper<any>, rows: RowWrapper<any>[]) {
    if (row.value == checkedRow.value) {
        row.checked = false;
        checkedRow.value = undefined;
    } else {
        rows.forEach(x=>x.checked = false);
        row.checked = true;
        checkedRow.value = row.value;
    }
}
function refreshAfterConfirm() {
    getPayments();
    checkedRow.value = undefined;
}
</script>
