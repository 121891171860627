<template>
    <div ref=container class="vtl-container">
        <div class="vtl-table-wrapper" :class="{empty:!showingFullTable, shrink:showingLoading}">
            <div ref='detector' class="detector"></div>
            <div class="sticky"></div>
            <table class="vtl-table">
                <thead>
                    <tr v-if="ColumnGroups">
                        <th v-for="group in ColumnGroups" :key="group.header" :colspan="group.columns.length" :class="['vtl-grouped-header', { 'has-header-title': group.header !== '' }]" >
                            {{ group.header }}
                        </th>
                    </tr>
                    <tr>
                        <slot name=pre-col-header></slot>
                        <th v-if="HasCheckBox && !SingleSelection" class="vtl-checkbox">
                            <input type="checkbox"
                                :disabled=nothingToCheck
                                :checked=allChecked 
                                @click="checkAll" />
                        </th>
                        <th v-if="SingleSelection && HasCheckBox" class="vtl-thead vtl-td-checkbox"></th>
                        <th v-for="(col, i) in Cols" :key="i"
                            :class="{ minwidth: minWidthCols ? minWidthCols[i] : false, overflow: overflow }">
                            <div class="vtl-header-filter-wrapper">
                                <div class="vtl-header-wrapper">
                                    <div class="vtl-header">
                                        <div :class="['vtl-header-content', { clickable:!colFilterDisabled(col) }]" @click="colFilterDisabled(col) ? undefined : togFilInpAtCol(i)" >
                                            <slot :name="`${slotKeys[i]}-header`" :value="col">
                                                {{ labels[i] }}
                                            </slot>
                                            <span v-if="filter[col]||nullFilterStates[col]" class="tail">
                                                &nbsp;<Filter class="vtl-filter-icon"/>
                                            </span>
                                        </div>
                                        <ArrowUpDown v-if=!DisableSort 
                                            class="vtl-sortable" 
                                            :model-value="sortCols.indexOf(i) >= 0 ? sortOrders[sortCols.indexOf(i)] === 1 ? ArrowState.UP : ArrowState.DOWN : ArrowState.BOTH" 
                                            @click="sortBy(i as number)"/>
                                    </div>
                                </div>
                                <div v-if="(LoadDataCallBack || !isLoading && !err) && filterShown === i" class="vtl-filter">
                                    <div class="vtl-filter-title">
                                        <div>Filter:</div>
                                        <MultiStateSVGButton v-model=nullFilterStates[col]
                                            @update:model-value=LoadDataCallBack?resetPage():applyFilter()
                                            :states=nullFilterSVGs 
                                            :title="nullFilterBtnTitles[nullFilterStates[col]??0]"
                                        />
                                    </div>
                                    <form v-if=LoadDataCallBack class="vtl-filter-input-cont" @submit.prevent="e=>onFilterChangeOK(col,((e.target as HTMLFormElement).children[0] as HTMLInputElement).value)">
                                        <input :disabled="nullFilterStates[col]===NullFilterState.NullOnly" :value="filter[col]">
                                        <button>Ok</button>
                                    </form>
                                    <input v-else :value="filter[col]"
                                        :disabled="nullFilterStates[col]===NullFilterState.NullOnly"
                                        @vue:mounted="e=>(e.el as HTMLElement).focus()"
                                        @input="e=>onFilterChangeLocalRows(col,(e.target as HTMLInputElement).value)" 
                                        @keypress="e=>{if(e.key==='Enter')filterShown=undefined;}"
                                    >
                                </div>
                            </div>
                        </th>
                        <slot name=post-col-header></slot>
                    </tr>
                </thead>
                <TransitionGroup v-if="!(HideTable || showingLoading)" :name=TableName tag="tbody">
                    <tr v-if=err class="vtl-loading-err">
                        <td :colspan=nDisplayCols>Error at loading data... Try refreshing the page...
                        </td>
                    </tr>
                    <tr v-else-if="!isLoading && numRecords == 0" class="vtl-empty-msg">
                        <td :colspan=nDisplayCols>{{ NoDataAvailableMessage }}</td>
                    </tr>
                    <template v-for="(row, i) in pageRows" :key=i>
                        <tr :class="['striped', { 'vtl-checked': row.checked, clickable:!!RowClickedCallBack, 'highlightable': (HasCheckBox || RowClickedCallBack) }]"
                            @click="RowClickedCallBack?.(row.value)"
                            :title="RowTitle?.(row.value)">
                            <slot name=pre-col :value=row.value ></slot>
                            <td v-if=HasCheckBox class="vtl-checkbox" @click="disableEventPropagation($event)">
                                <input type="checkbox" :value="ColKey ? row.value[ColKey as (keyof T)] : i"
                                    :disabled="RowCheckableFilter?.(row.value) === false"
                                    :checked="row.checked"
                                    @change="SingleSelection ? updateSingleCheck(row) : checkRow(row)" />
                            </td>
                            <td v-for="(col, j) in Cols" :key=j :class="[col, { overflow: overflow }]">
                                <slot :name="slotKeys[j]" :value="row.value">
                                    <div v-if="Array.isArray(col)">
                                        {{ col.map(key => row.value[key]).join(' ') }}
                                    </div>
                                    <div v-else>{{ access(row.value,col) }}</div>
                                </slot>
                            </td>
                            <slot name=post-col :value=row.value ></slot>
                        </tr>
                        <slot name=post-row :value=row.value ></slot>
                    </template>
                    <slot name=post-table></slot>
                </TransitionGroup>
            </table>
            <div class="vtl-paging" v-if=showPagingInfo>
                <template v-if=!MiniPaging>
                    <div class="vtl-paging-info col-md-4">
                        <div role="status" aria-live="polite" class="vtl-paging-span">
                            {{ stringFormat(PagingInfoMessage, rowStart, rowEnd, numRecords) }}
                        </div>
                    </div>
                    <ul class="vtl-pagination  col-md-4">
                        <li class="page-item" :class="{ disabled: page <= 1 }">
                            <div class="page-link" aria-label="Previous" @click="prevPage">
                                <span aria-hidden="true">&laquo;</span>
                                <span class="sr-only">Previous</span>
                            </div>
                        </li>
                        <li class="page-item" :class="{ active: page === 1 }">
                            <div class="page-link" aria-label="First" @click="movePage(1)">
                                <span aria-hidden="true">1</span>
                            </div>
                        </li>
                        <li v-if="pageSwitch.start > 3" class="page-item">
                            <div class="page-link placeholder" aria-label="...">
                                <span aria-hidden="true">...</span>
                            </div>
                        </li>
                        <li class="page-item" v-for="n in pageSwitch.num" :key="pageSwitch.start + n - 1"
                            :class="{ active: page === pageSwitch.start + n - 1 }">
                            <div class="page-link" @click="movePage(pageSwitch.start + n - 1)">{{ pageSwitch.start + n - 1 }}</div>
                        </li>
                        <li v-if="pageSwitch.end < maxPage - 2" class="page-item">
                            <div class="page-link placeholder" aria-label="...">
                                <span aria-hidden="true">...</span>
                            </div>
                        </li>
                        <li class="page-item" :class="{ active: page === maxPage }">
                            <div class="page-link" aria-label="Max" @click="movePage(maxPage)">
                                <span aria-hidden="true">{{ maxPage }}</span>
                            </div>
                        </li>
                        <li class="page-item" :class="{ disabled: page >= maxPage }">
                            <div class="page-link" aria-label="Next" @click="nextPage">
                                <span aria-hidden="true">&raquo;</span>
                                <span class="sr-only">Next</span>
                            </div>
                        </li>
                    </ul>
                </template>
                <div :class="['vtl-paging-change', { 'col-md-4':!MiniPaging } ]">
                    <span class="vtl-paging-count">{{ PageSizeChangeLabel }}</span>
                    <select class="vtl-paging-count" v-model=rowsPerPage @change="loadDataIfCallBack()">
                        <option v-for="pageOption in (PageOptions as Array<number>)" :value="pageOption" :key="pageOption">
                            {{ pageOption }}
                        </option>
                    </select>
                    <span class="vtl-paging-page">{{ GotoPageLabel }}</span>
                    <select class="vtl-paging-page" v-model=page @change="movePage(page)">
                        <option v-for="n in maxPage" :key="n" :value="n">
                            {{ n }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="vtl-paging" v-else-if="!HideTable && numRecords > rowsPerPageInitial">
                <template v-if=!MiniPaging>
                    <div class="vtl-paging-info col-md-4">
                        <div role="status" aria-live="polite" class="vtl-paging-span">
                            {{ stringFormat(PagingInfoMessage, rowStart, rowEnd, numRecords) }}
                        </div>
                    </div>
                    <ul class="vtl-pagination  col-md-4">
                    </ul>
                </template>
                <div :class="['vtl-paging-change', { 'col-md-4':!MiniPaging } ]">
                    <span class="vtl-paging-count">{{ PageSizeChangeLabel }}</span>
                    <select class="vtl-paging-count" v-model=rowsPerPage @change="loadDataIfCallBack">
                        <option v-for="pageOption in (PageOptions as Array<number>)" :value="pageOption" :key="pageOption">
                            {{ pageOption }}
                        </option>
                    </select>
                    <span class="vtl-paging-page">{{ GotoPageLabel }}</span>
                    <select class="vtl-paging-page" v-model=page @change="movePage(page)">
                        <option v-for="n in maxPage" :key="n" :value="n">
                            {{ n }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="vtl-paging" v-else-if="ShowTableCount && !HideTable && maxPage == 1 && numRecords != 0">
                <template v-if=!MiniPaging>
                    <div class="vtl-paging-info col-md-4">
                        <div role="status" aria-live="polite" class="vtl-paging-span">
                            {{ stringFormat(PagingInfoMessage, rowStart, rowEnd, numRecords) }}
                        </div>
                    </div>
                </template>
            </div>   
        </div>
        <Spinner v-show=showingLoading />
        <ScrollToTopButton v-if="sticky && showingFullTable" />
    </div>
</template>

<script setup lang="ts" generic="T, K extends KeyTypeKeys<T> | undefined=undefined, P extends boolean | undefined=undefined">
import { ref, Ref, computed, ComputedRef, onMounted, onUnmounted, watch, inject } from 'vue';
import ScrollToTopButton from './ScrollToTopButtonLite.vue';
import { ArrowState } from '@/types/enums/ArrowState';
import Spinner from './Spinner.vue';
import Sorter, { DisplayableKeys } from '@/services/SorterService';
import CancellablePromise, { PromiseCancelledError } from '@/types/CancellablePromise';
import PropCaseHelper from '@/services/helpers/PropCaseHelper';
import { loggerKey } from '@/types/ServiceKeys';
import useVModelDefaultVar from '@/services/composables/VModelDefaultVar';
import MultiStateSVGButton from './common/MultiStateSVGButton.vue';
// SVG
import ArrowUpDown from './svg/ArrowUpDown.vue';
import Filter from './svg/Filter.vue';
import Circle from './svg/Circle.vue';
import FilledCircle from './svg/FilledCircle.vue';
import MaskedTransition from './svg/MaskedTransition.vue';
import IFilter from '@/Interfaces/IFilter';

export type KeyTypeKeys<T, K extends keyof T=keyof T> = K extends any ? T[K] extends (string|number) ? K : never : never;
export type CheckRet<T, K extends KeyTypeKeys<T> | undefined, P extends boolean | undefined> = 
    K extends keyof T
        ? P extends true 
            ? T[K][]
            : T[]
        : T[];
export interface ITableProps<T,K extends KeyTypeKeys<T> | undefined=undefined,P extends boolean | undefined=undefined> {
    /**
    *  An array of T typed objects for displaying on the table
    */
    Rows: T[]|undefined; //can be updated by parent
    /**
    *  An array containing field names of T, or subarrays
    *   of field names if the cell uses multiple field of data
    *   when sorting, the last element is the most important
    * 
    *  example: if T has 'arms': Array<Arm>,'legs': Array<Leg>,'head': Head
    *  you can pass ['arms',['legs','head']]
    */
    Cols: DisplayableKeys<T>[];
    /**
     * The column headings you want to show on the table for each column
     * If the length doesn't match that of Cols, will show the field
     * names defined in Cols instead
    */
    Labels?: string[];
    /**
     * cols that need to be of min width
     */
    ColsMinWidth?: number[];
    /**
     * The unique key of each row for accessing rows
     * defined this to make checking persistent across data refresh (Rows getting reassigned)
     * If LoadDataCallback is defined, data refresh occurs at filtering and page change as well
    */
    ColKey?: K;
    //disabling type checking for ColKey because of vue bug.
    //ColKey?: keyof T;
    /**
     * Set to sort this column in ascending order by default if possible
     * must be an numeric index value
     */
    DefaultSortCols?: number[];
    /**
     * An array of 1 or -1 to indicate sort order of respective column
     * 1 for ascending and -1 for descending
     */
    DefaultSortOrders?: Array<-1 | 1>;
    NonFilterableColumns?: (keyof T)[];
    /**
     * When set to true show banner about loading error and disable all table displays
     */
    HasError?: unknown;
    /**
     * If true, show an extra column of checkboxes to the left
     * 
     * Default is false.
     */
    HasCheckBox?: boolean;
    /**
     * If true, adds an extra column in front. Customize header with the slot pre-col-header and the row with pre-col
     * 
     * Default is false.
     */
    HasPreCol?: boolean;
    /** Number of rows per page
     * A v-model. Number of rows per page when the page is displayed.
     * (The user may have a preference saved, pass it here)
     *
     * Default is 40.
     */
    RowsPerPage?: number;
    // data passed in may only be a subset of the total number of records
    /**
     * The total number of records for the current query from the database
     * for calculating number of pages. When data is fully loaded, you
     * can just pass Rows.length. Otherwise pass the number the server returns.
     */
    NumRecords?: number; //can be updated by parent
    //assumes when NumRecords!=Rows.length, needs to be dynamically loaded
    /**
     * Do I return the reference to checked rows or just the keys of those rows
     * If key is not defined, return entire row even if true.
     * 
     * Default is false. 
     */
    ReturnKey?: P;
    /** The current page the table is on.
     * A v-model. Change when user switches page using the pagination
     * buttons. Also gets reset when assigning new data in normal mode.
     * In callback mode, it only goes back to the maximum page when
     * the number of rows change to a lower number.
     * 
     * default is 1
     */
    Page?: number;
    /**
     * If true, disable sorting functionality
     */
    DisableSort?: boolean;
    DisableFilter?: boolean;
    /**
     * Array of page numbers to show on the screen for the dropdown box.
     * 
     * Default is [20,50,100].
     */
    PageOptions?: number[];
    ShowTableCount?: boolean;
    /**
     * Message to show when items have been checked. {0} for the number of items checked.
     */
    CheckedInfoMessage?: string;
    /**
     * The message displayed on the bottom left corner
     * {0} is the starting row on display, {1} is the last row on display
     * {2} is the current page
     * 
     * Default message is Showing {0}-{1} of {2}
     */
    PagingInfoMessage?: string;
    /**
     * The label in front of the dropdown box of page
     * 
     * Default is Row count:
     */
    PageSizeChangeLabel?: string;
    GotoPageLabel?: string;
    NoDataAvailableMessage?: string;
    /**
     * If true, hides the table but leaves the headings untouched
     */
    HideTable?: boolean;
    /**
     * Number of pagination buttons aside from the first, last page buttons
     */
    NumPaging?: number;
    RowTitle?: (item: T)=>string;
    TableName?: string;
    /** 
     * Make check all button to include items on other pages
     * Ignored when LoadDataCallback is defined, define ColKey instead to preserve checked items
     */
    CheckIncludesNonDisplayed?: boolean;
    CheckByDefault?: boolean;
    DisableCheckPersistentAcrossRefresh?: boolean;
    SplitColKeys?: boolean;
    ShowHeaderWhileLoading?: boolean;
    /** Hides rows beyond rows per page */
    DisablePaging?: boolean;
    MiniPaging?: boolean;
    /** @deprecated */
    SingleSelection?: boolean;
    RowCheckableFilter?: (row: T)=>boolean;
    /** Callback when checking or unchecking stuff
     * @row is the checked rows
     */
    CheckCallBack?: (row: CheckRet<T,K,P>)=>void;
    /**
     * define this to allow rows to be clicked
     * set to undefined if don't want rows to be clickable
     */
    RowClickedCallBack?: ((row: T) => void);
    // method to obtain the data, add filter and other options in the future?
    // or just make it a callback, data handled by parent component? if callback is null, it is static?
    // 1 means ascending, -1 for descending
    LoadDataCallBack?: ((offset: number, count: number, sortCols: number[], sortOrders: (-1 | 1)[], filter?: IFilter<T>) => CancellablePromise<void>);
    ColumnGroups?: { header: string; columns: string[] }[];}
export interface WrappedRow<T> {
    checked: boolean;
    value: T;
}
export interface ITableExpose<T,K extends KeyTypeKeys<T> | undefined=undefined,P extends boolean | undefined=undefined> {
    LoadData: ()=>void;
    GetChecked: ()=>CheckRet<T,K,P>;
    CheckAll: ()=>void;
    ClearChecked: ()=>void;
    ResetSort: ()=>void;
    ResetFilter: ()=>void;
    GetFiltered: ()=>T[];
}
export interface ITableEmit {
    (e: 'update:HasError', value: unknown);
    (e: 'update:Page', value: number);
    (e: 'update:RowsPerPage', value: number);
    // disabled because the condition for checking checks cleared needs a fix
    //(e: 'clear');
    (e: 'check');
    (e: 'uncheck');
}
enum NullFilterState {
    Off,
    NullOnly,
    NonNullOnly,
}
const nullFilterSVGs = [MaskedTransition,Circle,FilledCircle];
const nullFilterBtnTitles = ['Not filtering, click to toggle to filter for blanks', 'Showing only blanks, click to toggle to filter for non-empty', 'Showing only non-empty values, click to toggle off the filter'];

const emits = defineEmits<ITableEmit>();
const props = withDefaults(defineProps<ITableProps<T,K,P>>(), {
    PageOptions: () => [20, 40, 100],
    CheckedInfoMessage: 'You have checked {0} item(s). Click here to uncheck all.',
    PagingInfoMessage: 'Showing {0}-{1} of {2} item(s)',
    PageSizeChangeLabel: 'Row count:',
    GotoPageLabel: 'Go to page:',
    NoDataAvailableMessage: 'No data',
    NumPaging: 5,
});
const nullFilterStates: Ref<Partial<Record<DisplayableKeys<T>, NullFilterState>>> = ref({});
const filter: Ref<Partial<Record<DisplayableKeys<T>, string>>> = ref({});
const filterShown = ref<number>();
const log = inject(loggerKey);
const isLoading = ref(true);
const page = useVModelDefaultVar(props as ITableProps<T,K,P>, emits, 'Page', 1, pageChangeCallBack);
const numRecords = computed(() => 
    props.LoadDataCallBack ? props.NumRecords ?? 0 :
        filteredRows.value.length
);
const nDisplayCols = computed(()=>props.Cols.length + 20);
const labels = computed(()=>
    (!props.Labels||props.Labels.length!==props.Cols.length) ? 
        props.SplitColKeys ? props.Cols.map(x=>splitColKeyWords(x)) : props.Cols.map(x=>x.toString())
        : props.Labels);
const rowsPerPage = useVModelDefaultVar(props as ITableProps<T,K,P>, emits, 'RowsPerPage', 40);
const rowsPerPageInitial = ref(rowsPerPage.value);
/** when LoadCallBack is defined or CheckIncludesNonDisplayed is false
 * This is just the counter for numbers items checked on the current page
 * otherwise, it's number of checked items in filteredRows
 */
const checkCounter = ref(0);
const rowStart = computed(() => (page.value - 1) * rowsPerPage.value + 1);
const rowEnd = computed(() => {
    const limit = rowStart.value + rowsPerPage.value - 1;
    const n = numRecords.value ?? 0;
    const result = n >= limit ? limit : n;
    //log?.debug(`rowEnd updated: ${result}`);
    return result;
});
const maxPage = computed(() => {
    const n = numRecords.value ?? 0;
    if (n <= 0) return 1;
    const maxP = Math.ceil(n / rowsPerPage.value);
    //log?.debug(`maxP updated: ${maxP}`);
    if (maxP < page.value) page.value = maxP;
    return maxP;
});
//const maxPage = ref(3);
const pageSwitch = computed(() => {
    let num = props.NumPaging;
    const half = Math.floor(num / 2);
    let start = page.value - half; //to be a dynamic value based on page width
    start = (start < 1) ? 1 : start;
    if (start <= 2) {
        num += 1;
        if (start == 1) start += 1;
    }
    let end = start + num - 1;
    if (end == maxPage.value - 2) {
        end = maxPage.value - 1;
    }
    else if (end >= maxPage.value) {
        end = maxPage.value - 1;
        num += 1;
        start = end - num + 1;
        start = start < 2 ? 2 : start;
    }
    num = end - start + 1;
    const results = { start, end, num };
    //log?.debug(`pageSwitch updated: `);
    //log?.debug(results);
    return results;
});
const allChecked = computed(isAllChecked);
const nothingToCheck = computed(()=>getCheckableCount()<=0);
const sortCols = ref(props.DefaultSortCols ?? [] as number[]);
const sortOrders = ref(getDefaultSortOrder());
const wrappedRows: Ref<WrappedRow<T>[]> = ref([]);
const sortedRows: Ref<WrappedRow<T>[]> = ref([]);
const filteredRows: Ref<WrappedRow<T>[]> = ref([]);
const pageRows: ComputedRef<WrappedRow<T>[]> = computed(() =>
    props.LoadDataCallBack ? wrappedRows.value : filteredRows.value.slice(rowStart.value - 1, rowEnd.value)
);
const minWidthCols = computed(() => {
    if (props.ColsMinWidth) {
        const result = new Array<boolean>(props.Cols.length);
        result.fill(false);
        props.ColsMinWidth.forEach(i => { result[i] = true; });
        return result;
    } else return undefined;
});
const overflow = ref(false);
const sticky = ref(false);
const slotKeys = computed(getSlotNames);
const showPagingInfo = computed(() => {
    return maxPage.value > 1 && !(props.HideTable || showingLoading.value || props.DisablePaging);
});
const err = useVModelDefaultVar(props,emits,'HasError',false);
const showingFullTable = computed(()=>err.value || !isLoading.value || props.ShowHeaderWhileLoading);
const showingLoading = computed(()=>!(err.value || !isLoading.value));
//const showingFullTable = false;
//const showingLoading = true;
const detector = ref<HTMLElement>();
type NonNullableK<T, K extends keyof T | undefined> = K & keyof T;
const checkedItems: Ref<Map<T[NonNullableK<T,K>], T> | undefined> = ref();
const numChecked = computed(() => {
    return getChecked().length;
});
let callBackPromise: CancellablePromise | undefined;
let observer: IntersectionObserver | undefined;

defineExpose<ITableExpose<T,K,P>>({
    LoadData: resetPage, GetChecked: getChecked, ClearChecked: uncheckAll, ResetSort: resetSort, ResetFilter: resetFilter, CheckAll: checkAll, GetFiltered: getFiltered
});
watch(()=>props.Rows, assignLocalRows);
watch(()=>props.Cols, onChangeCols);
watch(pageRows, updateCheckCounter);
onMounted(() => {
    if (!loadDataIfCallBack()) assignLocalRows(props.Rows);
    window.addEventListener("resize", updateTablePosition);
    if (!detector.value) return console.log('detector not mounted! Report to vue team!');
    const stickyEl = detector.value.parentElement?.children[1];
    observer = new IntersectionObserver((e) => {
        //log?.debug('header intersection: ',e[0].intersectionRatio, e[0].boundingClientRect.bottom, stickyEl?.getBoundingClientRect?.().top, stickyEl);
        if (e[0].intersectionRatio === 0 && 
            e[0].boundingClientRect.bottom < (stickyEl?.getBoundingClientRect?.()?.top ?? 0)) {
            sticky.value = true;
        }
        else {
            sticky.value = false;
        }
    });
    observer.observe(detector.value);
});
onUnmounted(() => {
    window.removeEventListener("resize", updateTablePosition);
    observer?.disconnect();
    observer = undefined;
});
function onChangeCols(newCols: Array<keyof T | (keyof T)[]>, oldCols: Array<keyof T | (keyof T)[]>) {
    filterShown.value = undefined;
    updateSort(newCols, oldCols);
}
function updateSort(newCols: Array<keyof T | (keyof T)[]>, oldCols: Array<keyof T | (keyof T)[]>) {
    const flatNewColsMap = new Map(newCols.map((x, idx) => [x instanceof Array ? x.join('_') : x, idx]));
    const flatOldCols = oldCols.map(x => x instanceof Array ? x.join('_') : x);
    
    const combined = sortCols.value.map((i,ord) => [flatNewColsMap
        .get(flatOldCols[i]), sortOrders.value[ord]])
        .filter((x): x is [number,-1|1] => x[0] !== undefined);
    
    sortCols.value = combined.map(x => x[0]);
    sortOrders.value = combined.map(x => x[1]);
    sortedRows.value = sortRows(wrappedRows.value);
    applyFilter();
    //log?.debug("tablelite resetSort()",sortCols.value, sortOrders.value)
}
function access(item:T, key:keyof T) {
    const v = item[key];
    return (v instanceof Function) ? v.call(item) : v;
}
function assignLocalRows(rows: T[]|undefined, oldRows?: T[]) {
    isLoading.value = true;
    if (!rows) return;
    if (!(rows.length||oldRows?.length!==0)) { isLoading.value = false; return; }
    log?.debug('loading props.Rows into localRows\n','new:',rows,'\nold:',oldRows);
    const key = props.ColKey;
    wrappedRows.value = wrapRows(rows);
    if (key && props.DisableCheckPersistentAcrossRefresh) {
        // removing items that don't exist in current data
        checkedItems.value = new Map(wrappedRows.value.map(x=>[x.value[key], x.value]));
    }
    if (!props.LoadDataCallBack) {
        resetPage();
        sortedRows.value = sortRows(wrappedRows.value);
        applyFilter();
    }
    if (key && props.CheckByDefault) {
        if (oldRows) {
            const set = new Set(oldRows.map(x=>x[key]));
            const rowsToCheck = wrappedRows.value.filter(x=>!set.has(x.value[key])&&!x.checked);
            checkRows(rowsToCheck);
        } else {
            checkRows(wrappedRows.value);
        }
    }
    isLoading.value = false;
    setTimeout(() => {
        //log?.debug(`getLocalRows() ticking. changing loading state to: false`);
        //log?.debug(localRows.value);
        updateTablePosition();
    }, 0);
}
function wrapRows(rows: T[]) {
    if (props.ColKey && checkedItems.value) {
        const k = props.ColKey;
        const checked = checkedItems.value;
        const results = rows.map((row: T) => {
            return { value: row, checked: checked.has(row[k]), } as WrappedRow<T>;
        });
        return results;
    }
    return rows.map((row: T) => {
            return { value: row, checked: false, } as WrappedRow<T>;
        });
}
function isSortKeyByDefault(colKey?: keyof T) : colKey is K & DisplayableKeys<T> {
    if (colKey) {
        if(colKey !== props.ColKey) throw new Error('input must be ColKey');
    } else {
        colKey = props.ColKey;
    }
    return (!!colKey && !props.Cols.some(a=>a === colKey));
}
function getSortingKeys() {
    const k = props.ColKey;
    const base = sortCols.value.flatMap(i=>props.Cols[i]);
    //console.log('getSortingKeys',base);
    const result = isSortKeyByDefault(k) ? [...base,k] : base;
    return  result;
}
function getSortingOrders(): (-1|1)[] {
    const ordersMapped = sortCols.value.flatMap((colIdx,sortIdx)=> {
        const keys = props.Cols[colIdx];
        return (keys instanceof Array) ? keys.map(_=>sortOrders.value[sortIdx]) : sortOrders.value[sortIdx];
    });
    //console.log('getSortingOrders',ordersMapped);
    return isSortKeyByDefault() ? [...ordersMapped,1] : ordersMapped;
}
function sortRows(rows: WrappedRow<T>[]) {
    //sort it locally if there is no callback to get sorted data
    /* // refs https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Collator/compare
        const collator = new Intl.Collator('en-GB', {
        numeric: true,
        sensitivity: "base",
        }); */
    const sortColKeys = getSortingKeys();
    const sortOrders = getSortingOrders();
    if (sortColKeys.length > 0) {
        rows = [...rows].sort((a, b): number =>
            Sorter.CompareObjectWithMultipleKeys(a.value, b.value, sortColKeys, sortOrders));
        //console.log('sorted rows', rows, sortColKeys, sortOrders)
    }
    return rows;
}
function getDefaultSortOrder(): Array<1 | -1> {
    if (props.DefaultSortOrders) {
        if (props.DefaultSortOrders.length === props.DefaultSortCols?.length) {
            return props.DefaultSortOrders;
        }
    }
    return props.DefaultSortCols ?
        Array(props.DefaultSortCols.length).fill(1) :
        [] as Array<1 | -1>;
}
function updateTablePosition() {
    overflow.value = checkXOverflow();
    //log?.debug(`position updated. bottom: ${headerBot.value}. Is overflowing: ${overflow.value}`);
}
function checkXOverflow() {
    const cont = detector.value?.parentElement?.parentElement;
    return (cont?.clientWidth ?? 0) < (cont?.scrollWidth ?? 0);
}
function getCheckableCount() {
    const rowsToCheck = props.CheckIncludesNonDisplayed && !props.LoadDataCallBack ? filteredRows.value : pageRows.value;
    const filter = props.RowCheckableFilter;
    if (filter) return rowsToCheck.filter(x=>filter(x.value)).length;
    else return rowsToCheck.length;
}
function isAllChecked(): boolean {
    const n = getCheckableCount();
    return n > 0 && checkCounter.value >= n;
}
/** @deprecated */
function updateSingleCheck(row: WrappedRow<T>) {
    if (row.checked) {
        row.checked = false;
        if (props.ColKey) {
            checkedItems.value?.delete(row.value[props.ColKey]);
        }
        emits('uncheck');
    } else {
        uncheckAll();
        row.checked = true;
        if (props.ColKey) {
            if (!checkedItems.value) {
                checkedItems.value = new Map<T[NonNullableK<T,K>], T>();
            }
            checkedItems.value.set(row.value[props.ColKey],row.value);
        }
        emits('check');
    }
    props.CheckCallBack?.(getChecked());
}
/**
 * unchecks items across all pages (only filtered ones)
 */
function uncheckAll() {
    checkedItems.value?.clear();
    filteredRows.value.forEach(row => row.checked = false);
    checkCounter.value = 0;
    props.CheckCallBack?.(getChecked());
    //emits('clear');
}
function uncheckAllShown() {
    if (props.ColKey) {
        const key = props.ColKey;
        pageRows.value.filter(r=>r.checked).forEach(row => row.checked ? checkedItems.value?.delete(row.value[key]) : null);
    }
    let oldCounter = checkCounter.value;
    pageRows.value.forEach(row => { 
        if (row.checked) {
            oldCounter--;
            row.checked = false;
        }
    });
    checkCounter.value = oldCounter;
    props.CheckCallBack?.(getChecked());
    //if (oldCounter <= 0) emits('clear');
}
function checkRows(rowsToCheck: WrappedRow<T>[]) {
    if (props.RowCheckableFilter) {
        const filter = props.RowCheckableFilter;
        rowsToCheck.filter(x=>filter(x.value)).forEach(row => { row.checked = true; });
    }
    else rowsToCheck.forEach(row => { row.checked = true; });
    if (props.ColKey) {
        if (!checkedItems.value) {
            checkedItems.value = new Map<T[NonNullableK<T,K>], T>();
        }
        for (const row of rowsToCheck) {
            row.checked ? checkedItems.value.set(row.value[props.ColKey],row.value) : null;
        }
    }
    const oldCount = checkCounter.value;
    checkCounter.value = getCheckableCount();
    if (checkCounter.value > oldCount) emits('check');
    props.CheckCallBack?.(getChecked());
}
function checkAll() {
    if (isAllChecked()) props.CheckIncludesNonDisplayed ? uncheckAll() : uncheckAllShown();
    else {
        const rowsToCheck = props.CheckIncludesNonDisplayed && !props.LoadDataCallBack ? filteredRows.value : pageRows.value;
        checkRows(rowsToCheck);
    }
}
function checkRow(row: WrappedRow<T>) {
    if (row.checked) {
        row.checked = false;
        checkCounter.value -= 1;
        if (props.ColKey) {
            checkedItems.value?.delete(row.value[props.ColKey]);
        }
        //if (checkCounter.value === 0) emits('clear');
        //else
        emits('uncheck');
    } else {
        row.checked = true;
        checkCounter.value += 1;
        if (props.ColKey) {
            if (!checkedItems.value) {
                checkedItems.value = new Map<T[NonNullableK<T,K>], T>();
            }
            checkedItems.value.set(row.value[props.ColKey],row.value);
        }
        emits('check');
    }
    props.CheckCallBack?.(getChecked());
}
function getChecked() {
    const results = (props.HasCheckBox ? props.ColKey ? props.ReturnKey ? filterCheckedItems().map(x=>x[0]) :
        filterCheckedItems().map(x=>x[1]) :
        (props.CheckIncludesNonDisplayed ?  filteredRows.value : pageRows.value).filter(a => a.checked).map(a => a.value) :
        []
    ) as CheckRet<T,K,P>;
    //log?.debug(getChecked.name, results);
    return results;
}
function getFiltered(){
    return filteredRows.value.map(x=>x.value);
}
function updateCheckCounter() {
    if (props.HasCheckBox) {
        if (props.CheckIncludesNonDisplayed && !props.LoadDataCallBack) {
            checkCounter.value = filteredRows.value.reduce((count,x)=>x.checked?count+1:count,0);
        } else {
            checkCounter.value =  pageRows.value.reduce((count,x)=>x.checked?count+1:count,0);
        }
        props.CheckCallBack?.(getChecked());
    }
}
function updateSorted() {
    if (!loadDataIfCallBack()) {
        isLoading.value = true;
        sortedRows.value = sortRows(wrappedRows.value);
        applyFilter();
        isLoading.value = false;
    }
}
function sortBy(colIndex: number) {
    if (props.DisableSort) return;
    const idx = sortCols.value.indexOf(colIndex);
    if (idx >= 0) {
        if (sortOrders.value[idx] < 0) {
            sortCols.value.splice(idx, 1);
            sortOrders.value.splice(idx, 1);
        }
        else sortOrders.value[idx] = -1;
    } else {
        sortCols.value.unshift(colIndex);
        sortOrders.value.unshift(1);
    }
    updateSorted();
}
function stringFormat(template: string, ...args: any[]) {
    return template.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != "undefined" ? args[number] : match;
    });
}
function updatePage() {
    if (page.value > maxPage.value) page.value = maxPage.value > 0 ? maxPage.value : 1;
}
function clearCallbackRef() {
    callBackPromise = undefined;
}
function getFilterRequest() {
    const nullFilterVals = Object.entries(nullFilterStates.value).filter(([k,v])=>v!=NullFilterState.Off).map(
        ([k,v])=>v===NullFilterState.NullOnly?[`${k}NullsOnly`,""]:[k,""]
    );
    const filteredFilter = Object.fromEntries(Object.entries(filter.value).filter(([k,v])=>v));
    const filterReq = Object.assign(Object.fromEntries(nullFilterVals), filteredFilter);
    return filterReq;
}
function loadDataIfCallBack() {
    //log?.debug('---loadDataIfCallBack---');
    if (props.LoadDataCallBack) {
        const promise = props.LoadDataCallBack(rowStart.value-1,
            rowsPerPage.value,
            sortCols.value,
            sortOrders.value,
            getFilterRequest()
        );
        if (callBackPromise) callBackPromise.cancel();
        isLoading.value = true;
        callBackPromise = promise.then(() => {
            clearCallbackRef();
            updateTablePosition();
            updatePage();
        }, e=>{
            if (!(e instanceof PromiseCancelledError)) {
                err.value = e;
            }
        }) as CancellablePromise<void>;
        //log?.debug('---loadDataIfCallBack returns true---');
        return true;
    }
    //log?.debug('---loadDataIfCallBack returns false---');
    return false;
}
function pageChangeCallBack() {
    if (page.value > maxPage.value) page.value = maxPage.value;
    else loadDataIfCallBack();
}
function prevPage(): boolean {
    if (page.value == 1) return false;
    page.value--;
    return true;
}
function nextPage(): boolean {
    if (page.value >= maxPage.value) return false;
    page.value++;
    return true;
}
function movePage(p: number) {
    page.value = p;
}
function resetPage() {
    page.value = 1;
}
function disableEventPropagation(e: Event) {
    if (e.stopPropagation) {
        e.stopPropagation();
    } else if (window.event) {
        window.event.cancelBubble = true;
    }
}
function colKeyToName(col: keyof T | (keyof T)[]) {
    return Array.isArray(col) ? col.join('-') : (col as string);
}
function getSlotNames() {
    const results = new Array<string>(props.Cols.length);
    const counts: Record<string, number> = {};
    props.Cols.forEach((v, i) => {
        const newKey = colKeyToName(v);
        if (counts[newKey]) {
            results[i] = `${newKey}-${counts[newKey]++}`;
        }
        else {
            results[i] = newKey;
            counts[newKey] = 1;
        }
    });
    return results;
}
function seperateVarNameWords(str: string) {
    return PropCaseHelper.toSentenceName(str);
}
function splitColKeyWords(col: keyof T | (keyof T)[]) {
    if (col instanceof Array) {
        return seperateVarNameWords(col.join('-'));
    } else if (typeof col === 'symbol') {
        return seperateVarNameWords(col.toString());
    } else if (typeof col === 'string') {
        return seperateVarNameWords(col)
    } else {
        return col.toString();
    }
}
function resetSort() {
    sortCols.value = props.DefaultSortCols ?? [] as number[];
    sortOrders.value = getDefaultSortOrder();
    updateSorted();
}
function getStrFromDisplayable(x: T, v: T[DisplayableKeys<T>]): string {
    return (v instanceof Function) ? v.call(x) : v?.toString() ?? '';
}
function keyValueIncFilterStr(x: T, k: DisplayableKeys<T>, f: string) {
    const v = x[k];
    //if (typeof v==='number') return v === +f;
    const str = getStrFromDisplayable(x, v);
    return str.toLowerCase().includes(f.toLowerCase());
}
function stateValNonNullsGuard<K extends DisplayableKeys<T>,V>(v: [K,V|undefined]): v is [K,V] {
    return !!v[1];
}
function filterValNonNullsGuard<K extends DisplayableKeys<T>,V>(v: [K,V|undefined]): v is [K,V] {
    if (nullFilterStates.value[v[0]]===NullFilterState.NullOnly) return false;
    return !!v[1];
}
function* yieldFilteredItems(pairIterator: IterableIterator<[T[NonNullableK<T, K>], T]>, filteredNullFilterStates: [DisplayableKeys<T, keyof T>, NullFilterState][], filteredFilter: [DisplayableKeys<T, keyof T>, string][]) {
    for (const [rowKey, x] of pairIterator) {
        if (!(filteredNullFilterStates.some(([k,s])=> {
            const notEmpty = !getStrFromDisplayable(x, x[k]);
            return s===NullFilterState.NullOnly ? !notEmpty : notEmpty;
        })|| filteredFilter.some(([k,f])=>!keyValueIncFilterStr(x, k as DisplayableKeys<T>, f)))) {
            yield [rowKey, x];
        }
    }
}
function filterCheckedItems() {
    const pairIterator = checkedItems.value?.entries();
    if (pairIterator) {
        //log?.debug(getChecked.name, checkedItems.value);
        const filteredNullFilterStates = (Object.entries(nullFilterStates.value) as [DisplayableKeys<T>, NullFilterState|undefined][]).filter(stateValNonNullsGuard);
        const filteredFilter = (Object.entries(filter.value) as [DisplayableKeys<T>, string|undefined][]).filter(filterValNonNullsGuard);
        return [...yieldFilteredItems(pairIterator, filteredNullFilterStates, filteredFilter)]
    }
    return [];
}
function filterWrappedRows(rows: WrappedRow<T>[]) {
    const filteredNullFilterStates = (Object.entries(nullFilterStates.value) as [DisplayableKeys<T>, NullFilterState|undefined][]).filter(stateValNonNullsGuard);
    rows = filteredNullFilterStates.length ? rows.filter(
        x=>!filteredNullFilterStates.some(([k,s])=> {
            const notEmpty = !getStrFromDisplayable(x.value, x.value[k]);
            return s===NullFilterState.NullOnly ? !notEmpty : notEmpty;
        })
    ) : rows;
    const filteredFilter = (Object.entries(filter.value) as [DisplayableKeys<T>, string|undefined][]).filter(filterValNonNullsGuard);
    rows =  filteredFilter.length ? rows.filter(
        x=>!filteredFilter.some(([k,f])=>!keyValueIncFilterStr(x.value, k, f))
    ) : rows;
    return rows;
}
function onFilterChangeOK(col: DisplayableKeys<T>, newF: string) {
    filterShown.value = undefined;
    filter.value[col] = newF;
    // make sure this is called when loadCallBack is defined
    if (props.LoadDataCallBack) {
        resetPage();
    } else {
        onFilterChangeLocalRows(col, newF);
    }
}
function onFilterChangeLocalRows(col: DisplayableKeys<T>, newF: string) {
    const oldF = filter.value[col];
    filter.value[col] = newF;
    if ((oldF?.length ?? 0) < newF.length) {
        applyFilterInc();
    }
    else if (oldF !== newF) {
        applyFilter();
    }
}
function applyFilter() {
    filteredRows.value = filterWrappedRows(sortedRows.value);
}
function applyFilterInc() {
    filteredRows.value = filterWrappedRows(filteredRows.value);
}
function resetFilter() {
    filter.value = {};
    filterShown.value = undefined;
    filteredRows.value = sortedRows.value;
}
function colFilterDisabled(col: keyof T) {
    return props.DisableFilter || props.NonFilterableColumns?.includes(col);
}
function togFilInpAtCol(i: number) {
    filterShown.value === i ? (filterShown.value = undefined) : (filterShown.value = i);
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/table.scss";
</style>