<template>
    <Popup v-model=insurancePopUpStatus title="Create New Insurance Claim" hasClose>
        <template #contents>
            <div class="flex-col-centered">
               <br>
            </div>
            <div class="top-content">
                    <form @submit.prevent="RaiseInsuranceClaim">
                       <div class="grid-container">

                            <!--Market  input field -->
                            <label class="grid-item-left" for="market">Market</label>
                            <select id="market" class="dropdown" v-model="insuranceFormRequest.market" required disabled>
                                <option v-for="[market,display] in markets" :value="market" :key="market">
                                    {{ display }}
                                </option>
                            </select>

                            <!--Contract ID  input field -->
                            <label class="grid-item-left" for="contractId">Contract ID</label>
                            <input class="form-input invalid-input" type=text maxlength="24" placeholder=""  id="contractId" required v-model="insuranceFormRequest.contractId">
                            
                            <!--Payment Date input field -->
                            <span class="grid-item-left">
                                <ToolTipLabel warning tip="After inserting this value the only remaining editable field is comments" text="Payment Date" forId="datePicker"></ToolTipLabel>
                            </span>
                            <div class="date-picker" style="width: 100%">
                                <DatePicker
                                v-model="insuranceFormRequest.paymentDate"
                                type="date"
                                :enable-time-picker="false"
                                :format="FormatDatePicker"
                                placeholder="Select Payment Date..."
                                :text-input-options="textInputOptions"
                                text-input
                                :max-date="new Date()"
                                :teleport="true"
                                @input="HandleFullAmountRequirementChange"
                                id="datePicker"
                                class="invalid-input"
                                />
                            </div>
                            
                            <!--Case Reference input field -->
                            <label class="grid-item-left" for="caseReference">Case Reference ID</label>
                            <input class="form-input invalid-input" type=text maxlength=24 placeholder="" id="caseReference" v-model="insuranceFormRequest.caseReferenceId">

                            <!--Full Ammount input field -->
                            <span class="grid-item-left">
                                <ToolTipLabel information tip="Full insurance payment amount excluding excess" text="Full Amount" forId="fullAmount"></ToolTipLabel>
                            </span>
                            <CurrencyInput
                            v-model="insuranceFormRequest.fullAmount"
                            :min="0.01"
                            :currency="MarketCurrency[insuranceFormRequest.market]"
                            id="fullAmount"
                            :required="fullAmmountRequiredFields"
                            class="invalid-input"
                            />
                            
                            <!--excess Ammount input field -->
                            <span class="grid-item-left">
                                <ToolTipLabel information tip="Insurance company excess amount" text="Excess Amount" forId="excess"></ToolTipLabel>
                            </span>
                            <CurrencyInput
                            v-model="insuranceFormRequest.excessAmount"
                            :min="0"
                            :currency="MarketCurrency[insuranceFormRequest.market]"
                            id="excess"
                            class="invalid-input"
                            />

                            <!--Company Name input field -->
                            <span class="grid-item-left">
                                <ToolTipLabel information tip="Name of the insurance company handling the case" text="Company Name" forId="companyName"></ToolTipLabel>
                            </span>
                            <input class="form-input invalid-input" type=text maxlength=30 id="companyName"  v-model="insuranceFormRequest.companyName">
                            

                            <!--Company VAT input field -->
                            <span class="grid-item-left">
                                <ToolTipLabel information tip="VAT ID of the insurance company handling the case" text="Company VAT ID" forId="companyVat"></ToolTipLabel>
                            </span>
                            <input class="form-input invalid-input"  type=text maxlength=30 id="companyVat" v-model="insuranceFormRequest.companyVatId">
                            

                            <!--Company Location input field -->
                            <span class="grid-item-left">
                                <ToolTipLabel information tip="Location the insurance company handling the case" text="Company Location" forId="companyLocation"></ToolTipLabel>
                            </span>
                            <input class="form-input invalid-input" type=text maxlength=30 id="companyLocation" v-model="insuranceFormRequest.companyLocation">
                            
                            <!--comments input field -->
                            <label class="grid-item-left" for="comments">Comments</label>
                            <textarea  class="form-input textarea-input"  maxlength=4096 spellcheck="true" rows="4" id="comments" v-model="insuranceFormRequest.comments"> </textarea> 
                        
                        </div>
                        <div class="flex-col-centered">
                        <button class="submit-btn" type="submit">
                            CONFIRM
                        </button>
                        </div>
                    </form>
            </div>
        </template>
    </Popup>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import DatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Popup from "./Popup.vue";
import useNotifierWithErrFormatter from "@/services/composables/NotifierWithErrFormatter";
import useSpinnerPopup from "@/services/composables/SpinnerPopup";
import ToolTipLabel from "@/components/ToolTipLabel.vue";
import CurrencyInput from "@/components/CurrencyInput.vue";
import { MarketCurrency } from "@/services/helpers/Helper";
import { insuranceClaims } from "@/services/network";
import IInsuranceForm from "@/Interfaces/InsuranceClaims/IInsuranceClaimsForm";
import {  MarketSelection } from '@/services/helpers/Helper';
import DateIgnoreTime from "@/types/DateIgnoreTime";
import { authService } from "@/auth/authSingleton";

const markets = Object.entries(MarketSelection);
const insuranceFormRequest= ref<IInsuranceForm>({
    market: 'GB',
    contractId: "",
    userEmail: ""
});
const FormatDatePicker = (date: Date) => {
    return date.toLocaleString("sv-SE").split(" ")[0].replaceAll("-", "/");
};
const textInputOptions = { format: "yyyy/MM/dd" };
const props = defineProps<{
    modelValue?: boolean;
    market: string;
}>();
const insurancePopUpStatus = computed({
    get() {
        if(props.modelValue == true){
            insuranceFormRequest.value.market = props.market as keyof typeof MarketSelection
            insuranceFormRequest.value.userEmail = authService.getEmailAddress()
        }
        return props.modelValue
    },
    set(val) {
        emits('update:modelValue', val);
    }
});
const emits = defineEmits<{
    (e: 'update:modelValue', val?: boolean);
    (e: 'confirm');
}>();
const working = ref(false);
function closeChild(){
    insurancePopUpStatus.value = false;
    insuranceFormRequest.value = {} as IInsuranceForm;
}
const fullAmmountRequiredFields = ref(false)
function HandleFullAmountRequirementChange(event: Event): void{
    const target = event.target as HTMLInputElement;
    if(target.value != ""){
        fullAmmountRequiredFields.value = true
    }else{
        fullAmmountRequiredFields.value = false
    }
}
async function RaiseInsuranceClaim() {
    if(working.value) return;    
    const closeSpinner = useSpinnerPopup().show();
    try{
        if(insuranceFormRequest.value.paymentDate?.isValid()  && (insuranceFormRequest.value.fullAmount == null || insuranceFormRequest.value.fullAmount.toString() === '')){
            useNotifierWithErrFormatter().warn({msg: "When specifying 'Payment Date' remeber to specify 'Full Amount'"});
        }
        else{
            working.value=true
            let postRequest: IInsuranceForm = insuranceFormRequest.value;    
            postRequest.paymentDate = new DateIgnoreTime(postRequest.paymentDate)
            await insuranceClaims.post(postRequest)
            useNotifierWithErrFormatter().success({ msg: "Insurance Claim successfully registered." });
            emits("confirm");
            closeChild()
        }
    }catch(error){
        useNotifierWithErrFormatter().error({error});
    }
    closeSpinner()
    working.value=false
}
</script>
<style scoped lang="scss">
.grid-item-left {
    margin: auto 3px auto auto;
}
.grid-container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 0.5rem;
}
.textarea-input{
    resize: none;
    border-radius: 0.5rem;
    border: var(--border-width) solid var(--primary-color-dark);
    width: 30em;
    height: 10em
}
.submit-btn {
    margin-top: 1rem;
    cursor: pointer;
    border-width: 0px;
    border-radius: var(--buttons-border-radius);
    background-color: var(--primary-color-lighter);
    padding: 10px;
    color: blue;
    font-size: larger;
    font-weight: bold;
    &:hover {
        color: white;
        background-color: var(--primary-color);
    }
}
.invalid-input:invalid {
    color: red; border-color: red; outline: none;
}
</style>